import { useSelector } from 'react-redux'
import LogedRouter from './LogedRouter'
import UnLogedRouter from './UnLogedRouter'
import _ from 'lodash'
import { BrowserRouter as Router } from 'react-router-dom'

const AppRouter = () => {
  const { user } = useSelector(state => state.loginusu)

  return (
    <>
      {!_.isEmpty(user) && (
        <Router>
          <LogedRouter />
        </Router>
      )}
      {_.isEmpty(user) && <UnLogedRouter />}
    </>
  )
}

export default AppRouter
