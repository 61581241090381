import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  solicitudes: [],
  solPendParCant: 0,
  totalCount: 0,
  loading: false,
}

export const solSlice = createSlice({
  name: 'sol',
  initialState,
  reducers: {
    setSolicitudes: (state, action) => {
      state.solicitudes = action.payload
    },
    setSolPendParCant: (state, action) => {
      state.solPendParCant = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setSolicitudes, setSolPendParCant, setTotalCount, setLoading } =
  solSlice.actions
export default solSlice.reducer
