import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import { numericFormatter } from 'react-number-format'
import { useEffect, useState } from 'react'
import * as server from '../../../Axios'
import { useNavigate, useParams } from 'react-router-dom'

const AddProdModal = ({ setAddModalData }) => {
  const navigate = useNavigate()

  const { id_prod } = useParams()
  const [stockDetalle, setDetalle] = useState([])
  const [loading, setLoading] = useState(false)

  const find = async () => {
    setLoading(true)
    await server
      .getStockDetalle({
        id_prod_filter: id_prod,
      })
      .then(response => {
        setDetalle([...response.data.entities])
      })
      .catch(error => ({ error: error }))
      .finally(() => {
        setLoading(false)
      })
  }
  const onClick = s => () => {
    setAddModalData({
      producto: {
        prod_sku: s.prod_sku,
        prod_nombre: s.prod_nombre,
        id_producto: Number(id_prod),
      },
      id_bodega: s.id_bodega,
      id_canal_dist: s.id_canal_dist,
      stock: Number(s.stock),
    })

    closeModal()
  }
  const closeModal = () => {
    navigate(-1) // Cerrar modal y regresar a la página anterior
  }

  useEffect(() => {
    if (id_prod) find()
  }, [id_prod])
  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Seleccionar origen
        </Typography>
        {loading && (
          <Typography id="modal-modal-title" component="span">
            Cargando...
          </Typography>
        )}
        <Table>
          <TableBody>
            {!loading &&
              stockDetalle &&
              stockDetalle.map(s => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {s.prod_sku}
                  </TableCell>
                  <TableCell align="right">{s.prod_nombre}</TableCell>
                  <TableCell align="right">{s.bod_nombre}</TableCell>
                  <TableCell align="right">{s.canal_dist_nombre}</TableCell>
                  <TableCell align="right">
                    {numericFormatter(s.stock.toString(), {
                      thousandSeparator: '.',
                      decimalSeparator: ',',
                      decimalScale: 0,
                    })}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={onClick(s)}>Agregar</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export default AddProdModal
