import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  categorias: [],
  totalCount: 0,
  loading: false,
}

export const comSlice = createSlice({
  name: 'cat',
  initialState,
  reducers: {
    setCategorias: (state, action) => {
      state.categorias = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setCategorias, setTotalCount, setLoading } = comSlice.actions
export default comSlice.reducer
