import * as React from 'react'
import { PieChart } from '@mui/x-charts'
import { Paper } from '@mui/material'

export default function Chart({ entrantes, salientes }) {
  return (
    <React.Fragment>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 305,
          justifyContent: 'center',
        }}>
        <PieChart
          series={[
            {
              data: [
                {
                  id: 0,
                  value: entrantes,
                  label: 'Entrantes',
                  color: '#12827C',
                },
                {
                  id: 1,
                  value: salientes,
                  label: 'Salientes',
                  color: '#F64E60',
                },
              ],
              valueFormatter: v => `${v.value}%`,
              innerRadius: 30,
              paddingAngle: 1,
              cornerRadius: 5,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 15, additionalRadius: -15, color: 'gray' },
            },
          ]}
          width={500}
        />
      </Paper>
    </React.Fragment>
  )
}
