import { setSolicitudesPend } from '../dashRedux'
import { getSolicitudPend } from '../restFunctios/getSolicitudPend'

export const getSolicitudPendAction = q => {
  return async dispatch => {
    const { data } = await getSolicitudPend(q)
    if (data) {
      dispatch(setSolicitudesPend(data.entities))
    } else {
      dispatch(setSolicitudesPend([]))
    }
  }
}