import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stockDetalle: [],
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'stockDetalle',
  initialState,
  reducers: {
    setStockDetalle: (state, action) => {
      state.stockDetalle = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setStockDetalle, setTotalCount, setLoading } = Slice.actions
export default Slice.reducer
