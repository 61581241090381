import { useSelector } from 'react-redux'

const Protected = ({ children, validPermissions }) => {
  const { user } = useSelector(state => state.loginusu)

  if (!validPermissions) return <></>

  const hasValid = validPermissions.some(value =>
    user?.permisos?.map(v => v.id_permiso).includes(value),
  )

  return hasValid ? children : <></>
}

export default Protected
