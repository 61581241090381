import React from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { cleanAllAlerts } from './Redux/alerts/alertsRedux'
import { Snackbar } from '@mui/material'

export const AlertMessage = () => {
  const dispatch = useDispatch()
  const { alerts, show } = useSelector(state => state.alerts, shallowEqual)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    if (reason === 'timeout') dispatch(cleanAllAlerts())
  }

  return (
    <div>
      {alerts &&
        alerts.map((alert, index) => (
          <Snackbar
            style={{ marginTop: 50 * (index) }}
            key={index}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={show}
            autoHideDuration={3000}
            onClose={handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{alert.msg}</span>}
          />
        ))}
    </div>
  )
}
