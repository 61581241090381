import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/tras_inv'

export const insertTrasInv = (
  productos,
  bodega,
  canal,
  bodegaDestino,
  canalDestino,
) =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, {
    productos: productos,
    id_bodega_origen: bodega,
    id_canal_dist_origen: canal,
    id_bodega_destino: bodegaDestino,
    id_canal_dist_destino: canalDestino,
  })
