import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSalidasAction } from '../Redux/dash/actions/getSalidasAction'

const useDashSalidas = q => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSalidasAction(q))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q])
}

export default useDashSalidas
