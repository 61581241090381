import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/categorias'

export const insertCategoria = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getCategorias = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getCategoria = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateCategoria = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteCategorias = ids =>
  axios.get(`${API_URL}/${REQUEST_URL}/${ids}`)
