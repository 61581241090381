import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/centros_costo'

export const insertCentrosCosto = c =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getCentrosCosto = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getCentroCosto = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateCentrosCosto = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteCcosto = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })