import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/tomas_inventario'

export const insertTomaInventario = (inventario, detalle) =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, {
    inventario,
    detalle,
  })
export const terminarTomaInventario = id =>
  axios.put(`${API_URL}/${REQUEST_URL}/terminarTomaInventario/${id}`)
export const insertDetalleTomaInventario = (id, detalle) =>
  axios.put(`${API_URL}/${REQUEST_URL}/detalleTomaInventario/${id}`, detalle)
export const deleteDetalleTomaInventario = (id, id_producto) =>
  axios.delete(
    `${API_URL}/${REQUEST_URL}/detalleTomaInventario/${id}/${id_producto}`,
  )
export const getTomasInventario = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getTomaInventario = id =>
  axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateTomaInventario = (id, inventario, detalle) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, {
    inventario,
    detalle,
  })
