import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/usuarios'

export const register = u =>
  axios.post(`${API_URL}/${REQUEST_URL}/register`, { ...u })
export const login = u =>
  axios.post(`${API_URL}/${REQUEST_URL}/login`, { ...u })
export const insertUser = c =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, { usu: c })
export const getUsers = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getUser = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)

export const updateUser = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, { usu: c })
export const updatePerfilUsu = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/perfil/${id}`, { usu: c })
export const updateAvatar = (id, url_avatar) =>
  axios.put(`${API_URL}/${REQUEST_URL}/avatar/${id}`, { url_avatar })
export const deleteUsers = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })

export const getEmpresa = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getempresa`, { params: q })
export const updateEmpresa = dt =>
  axios.put(`${API_URL}/${REQUEST_URL}/updatempresa`, { data: dt })