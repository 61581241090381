import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stockBodega: [],
  stockBodGraphic: {
    StockGenGraphic: [],
  },
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'stockBodega',
  initialState,
  reducers: {
    setStockBodega: (state, action) => {
      state.stockBodega = action.payload
    },
    setStockBodGraphic: (state, action) => {
      state.stockBodGraphic = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setStockBodGraphic, setStockBodega, setTotalCount, setLoading } =
  Slice.actions
export default Slice.reducer
