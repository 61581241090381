import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/perfiles'

export const insertPerfil = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getPerfiles = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getPerfil = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updatePerfil = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
