import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { AccountCircle, Logout, Person } from '@mui/icons-material'
import { logout } from '../../Redux/loginusu/loginusuRedux'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import NavLink from './NavLink'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const AppTopBar = ({ label, openSidebar, toggleDrawer }) => {
  const { user } = useSelector(state => state.loginusu)
  const { solPendParCant } = useSelector(state => state.sol)

  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogOut = () => {
    dispatch(logout())
  }

  const drawerWidth = 240

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
      open={openSidebar}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer}
          sx={{ mr: 2, display: { sm: 'none' } }}>
          <MenuIcon />
        </IconButton>

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit">
            <Badge badgeContent={solPendParCant} color="error">
              <BookmarkIcon />
            </Badge>
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Menu
            id="menu-cuenta"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem to={'/myprofile'} component={NavLink}>
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              <ListItemText>Mi Perfil</ListItemText>
            </MenuItem>
            <MenuItem to={'/myprofileconfi'} component={NavLink}> 
              <ListItemIcon>
                <ManageAccountsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Configuración</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText>Salir</ListItemText>
            </MenuItem>
          </Menu>

          <Button
            variant="contained"
            size="large"
            onClick={handleMenu}
            aria-label="Cuenta"
            aria-controls="menu-cuenta"
            aria-haspopup="true"
            disableElevation
            endIcon={<AccountCircle />}>
            {user.usu_nombre}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default AppTopBar
