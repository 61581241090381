import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/stock'

export const getStockBodCanByIdAndProd = (id_bod, id_canal, productos) =>
  axios.get(`${API_URL}/${REQUEST_URL}/bod_canal/${id_bod}/${id_canal}`, {
    params: { productos },
  })
export const getProdStockBodCan = (id_bod, id_canal) =>
  axios.get(
    `${API_URL}/${REQUEST_URL}/prod_stock_bod_can/${id_bod}/${id_canal}`,
  )