import Grid from '@mui/material/Grid'
import Chart from './components/Chart'
import Salidas from './components/Salidas'
import SolicitudPend from './components/SolicitudesPend'
import { Box, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import Title from './components/Title'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DashData from './components/DashData'
import Link from '@mui/material/Link'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import useDashResumen from '../../Hooks/useDashResumen'
import { useSelector } from 'react-redux'
import useDashPorcentaje from '../../Hooks/useDashPorcentaje'
import useDashSalidas from '../../Hooks/useDashSalidas'
import useSolPendParCant from '../../Hooks/useSolPendParCant'
import useDashSolicitudPend from '../../Hooks/useDashSolicituPend'

const DashboardPage = () => {
  const { resumen, porcentajeSalEnt, salidas, solicitudesPend } = useSelector(
    state => state.dash,
  )
  useDashResumen()
  useDashPorcentaje()
  useDashSalidas()
  useSolPendParCant()
  useDashSolicitudPend()

  const nav = useNavigate()
  const preventDefault = event => {
    event.preventDefault()
    nav(`/solicitudes`)
  }

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Box>
            <Typography component="h1" variant="h5" color="inherit">
              Resumen {moment().format('MMM YYYY')}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DashData
                label="Productos salientes"
                data1={resumen?.salidas ?? 0}
                variant="danger"
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Productos entrantes"
                data1={resumen?.entradas ?? 0}
                variant="success"
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Solicitudes"
                data1={resumen?.solicitudes ?? 0}
                variant="secondary"
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Sol. Pendientes"
                data1={resumen?.solPendientes ?? 0}
                variant="warning"
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Sol. Completadas"
                data1={resumen?.solCompletadas ?? 0}
                variant="secondary"
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Sol. Parciales"
                data1={resumen?.solParciales ?? 0}
                variant="secondary"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Chart */}
        <Grid item xs={12} md={6}>
          <Chart
            entrantes={porcentajeSalEnt.entradas ?? 0}
            salientes={porcentajeSalEnt.salidas ?? 0}
          />
        </Grid>

        {/* Recent Orders */}
        <Grid item xs={12}>
          <Salidas rows={salidas} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Title>Solicitudes Pendientes</Title>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Nombre solicitud</TableCell>
                    <TableCell>Solicitante</TableCell>
                    <TableCell>Nombre tienda</TableCell>
                    <TableCell>Direccion</TableCell>
                    <TableCell>Entregar a</TableCell>
                    <TableCell>Estado solicitud</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {solicitudesPend.map((row, index) => (
                    <SolicitudPend key={row.id} idx={index} row={row} />
                  ))}
                </TableBody>
                <Link
                  href={'/solicitudes'}
                  color="primary"
                  onClick={preventDefault}
                  sx={{ mt: 3 }}>
                  Ver más
                </Link>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DashboardPage
