import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modulos: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'modulos',
  initialState,
  reducers: {
    setModulos: (state, action) => {
      state.modulos = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setModulos, setTotalCount } = Slice.actions
export default Slice.reducer
