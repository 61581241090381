import { combineReducers } from 'redux'

import alertsReducer from './alerts/alertsRedux'
import loginusuReducer from './loginusu/loginusuRedux'
import usersReducer from './users/usersRedux'
import catReducer from './categorias/catRedux'
import prodReducer from './productos/prodRedux'
import bodReducer from './bodegas/bodRedux'
import entReducer from './entradas/entRedux'
import solReducer from './solicitudes/solRedux'
import salReducer from './salidas/salRedux'
import stockRedux from './stock/stockRedux'
import dashRedux from './dash/dashRedux'
import sub_catRedux from './sub_categorias/sub_catRedux'
import marcasRedux from './marcas/marcasRedux'
import centros_costoRedux from './centros_costo/centros_costoRedux'
import tiendasRedux from './tiendas/tiendasRedux'
import perfilesRedux from './perfiles/perfilesRedux'
import modulosRedux from './modulos/modulosRedux'
import permisosRedux from './permisos/permisosRedux'
import zonasRedux from './zonas/zonasRedux'
import despachosRedux from './despachos/despachosRedux'
import stockBodegaRedux from './stock_bodega/stockBodegaRedux'
import cadenasRedux from './cadenas/cadenasRedux'
import tiposEntRedux from './tipos_entradas/tiposEntRedux'
import canalesDistRedux from './canales_dist/canalesDistRedux'
import stockCanalesRedux from './stock_canales/stockCanalesRedux'
import ProveedoresRedux from './proveedores/ProveedoresRedux'
import stockDetalleRedux from './stock_detalle/stockDetalleRedux'
import tiposSalRedux from './tipos_salidas/tiposSalRedux'
import demandaRedux from './demanda/demandaRedux'
import stockCriticoRedux from './stock_critico/stockCriticoRedux'
import tomas_inventarioRedux from './tomas_inventario/tomas_inventarioRedux'
import desviaciones from './deviaciones/desviacionesRedux'
export const rootReducer = combineReducers({
  prod: prodReducer,
  ent: entReducer,
  bod: bodReducer,
  alerts: alertsReducer,
  loginusu: loginusuReducer,
  users: usersReducer,
  cat: catReducer,
  sol: solReducer,
  sal: salReducer,
  stock: stockRedux,
  dash: dashRedux,
  sub_cat: sub_catRedux,
  marcas: marcasRedux,
  centros_costo: centros_costoRedux,
  tiendas: tiendasRedux,
  perfiles: perfilesRedux,
  modulos: modulosRedux,
  permisos: permisosRedux,
  zonas: zonasRedux,
  despachos: despachosRedux,
  stockBodega: stockBodegaRedux,
  cadena: cadenasRedux,
  tiposEnt: tiposEntRedux,
  canalesDist: canalesDistRedux,
  stockCanales: stockCanalesRedux,
  proveedores: ProveedoresRedux,
  stockDetalle: stockDetalleRedux,
  tiposSal: tiposSalRedux,
  demanda: demandaRedux,
  stockCritico: stockCriticoRedux,
  tomas_inventario: tomas_inventarioRedux,
  desviaciones: desviaciones,
})
