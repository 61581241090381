import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSolPendParCantAction } from '../Redux/solicitudes/actions/getSolPendParCantAction'

const useSolPendParCant = q => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSolPendParCantAction(q))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q])
}

export default useSolPendParCant
