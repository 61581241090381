import { Box, Divider, List, Toolbar } from '@mui/material'
import { adminListItems, mainListItems, SecondaryListItems } from './listItems'
import { useSelector } from 'react-redux'
import Drawer from '@mui/material/Drawer'

const AppSideBar = ({ openSidebar, closeDrawer }) => {
  const { user } = useSelector(state => state.loginusu)
  const drawerWidth = 240
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={closeDrawer}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open={openSidebar}>
        <DrawerContent user={user} />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open>
        <DrawerContent user={user} />
      </Drawer>
    </Box>
  )
}

const DrawerContent = () => (
  <>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pl: 1,
      }}>
      <img src="/images/logo.png" alt="logo" width={180} />
    </Toolbar>
    <Divider className="divider" />
    <List component="nav">
      {mainListItems}
      <Divider sx={{ my: 1 }} className="divider" />
      <SecondaryListItems />
      {renderAdminListItems(adminListItems)}
    </List>
  </>
)

const renderAdminListItems = adminListItems => {
  return (
    <>
      <Divider sx={{ my: 1 }} className="divider" />
      {adminListItems}
    </>
  )
}

export default AppSideBar
