import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sub_categorias: [],
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'sub_cat',
  initialState,
  reducers: {
    setSubCategorias: (state, action) => {
      state.sub_categorias = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setSubCategorias, setTotalCount, setLoading } = Slice.actions
export default Slice.reducer
