import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stockCanales: [],
  stockCanGraphic: {
    StockGenCanGraphic: [],
  },
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'stockCanales',
  initialState,
  reducers: {
    setStockCanales: (state, action) => {
      state.stockCanales = action.payload
    },
    setStockCanGraphic: (state, action) => {
      state.stockCanGraphic = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setStockCanGraphic, setStockCanales, setTotalCount, setLoading } = Slice.actions
export default Slice.reducer
