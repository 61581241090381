import { Box, Container, CssBaseline, Toolbar } from '@mui/material'
import { AlertMessage } from '../../AlertMessage'
import AppTopBar from './AppTopBar'
import AppSideBar from './AppSideBar'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarOpen } from '../../Redux/loginusu/loginusuRedux'
import { useEffect } from 'react'
import useWindowSize from '../../Hooks/useWindowSize'

const Layout = ({ label, children }) => {
  const { openSidebar } = useSelector(state => state.loginusu)
  const [width] = useWindowSize()
  const dispatch = useDispatch()

  const toggleDrawer = () => {
    dispatch(setSidebarOpen(!openSidebar))
  }
  const closeDrawer = () => {
    dispatch(setSidebarOpen(false))
  }

  useEffect(() => {
    if (width > 600 && openSidebar) dispatch(setSidebarOpen(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, openSidebar])

  const drawerWidth = 240

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AlertMessage />
      <AppTopBar
        label={label}
        toggleDrawer={toggleDrawer}
        openSidebar={openSidebar}
      />
      <AppSideBar openSidebar={openSidebar} closeDrawer={closeDrawer} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          maxWidth: '100vw',
          backgroundColor: '#fafafa',
        }}>
        <Toolbar />
        <Container maxWidth="100%" sx={{ mt: 2, mb: 4 }}>
          {children}
        </Container>
      </Box>
    </Box>
  )
}

export default Layout
