import axios from 'axios'
import { API_URL } from './Cnx'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'

const REQUEST_URL = 'api/productos'

export const getRandSku = () => axios.get(`${API_URL}/${REQUEST_URL}/randSku`)
export const insertProducto = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getProductos = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getProducto = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateProducto = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteProducto = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })
export const createPresignedUrlWithClient = async ({
  bucket,
  key,
  contentType,
}) => {
  const region = 'sa-east-1'
  const client = new S3Client({
    region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region }),
      identityPoolId: 'sa-east-1:cd080295-f30a-4d28-a3bc-2e09b63cf27d',
    }),
  })

  const command = new PutObjectCommand({
    Bucket: bucket,
    Key: key,
    ContentType: contentType,
  })
  return {
    method: 'PUT',
    url: await getSignedUrl(client, command, { expiresIn: 3600 }),
    headers: {
      'Content-Type': contentType,
    },
  }
}
