import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  productos: [],
  totalCount: 0,
  loading: false,
}

export const comSlice = createSlice({
  name: 'prod',
  initialState,
  reducers: {
    setProductos: (state, action) => {
      state.productos = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setProductos, setTotalCount, setLoading } = comSlice.actions
export default comSlice.reducer
