import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  entradas: [],
  totalCount: 0,
  loading: false,
}

export const comSlice = createSlice({
  name: 'ent',
  initialState,
  reducers: {
    setEntradas: (state, action) => {
      state.entradas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setEntradas, setTotalCount, setLoading } = comSlice.actions
export default comSlice.reducer
