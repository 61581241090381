import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stock: [],
  dataPieCat: [],
  dataPieMarca: [],
  dataPieSubCat: [],
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setStock: (state, action) => {
      state.stock = action.payload
    },
    setDataPieCat: (state, action) => {
      state.dataPieCat = action.payload
    },
    setDataPieSubCat: (state, action) => {
      state.dataPieSubCat = action.payload
    },
    setDataPieMarca: (state, action) => {
      state.dataPieMarca = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const {
  setDataPieSubCat,
  setDataPieMarca,
  setDataPieCat,
  setStock,
  setTotalCount,
  setLoading,
} = Slice.actions
export default Slice.reducer
