import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  marcas: [],
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'marcas',
  initialState,
  reducers: {
    setMarcas: (state, action) => {
      state.marcas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setMarcas, setTotalCount, setLoading } = Slice.actions
export default Slice.reducer
