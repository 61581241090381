import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/tiendas'

export const insertTiendas = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getTiendas = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getTienda = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateTiendas = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteTienda = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })