import axios from 'axios'
import { API_URL, SII_API_URL } from './Cnx'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'

const REQUEST_URL = 'api/salidas'

export const insertSalida = s =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, {
    solicitud: s,
  })
export const reducirInventario = s =>
  axios.post(`${API_URL}/${REQUEST_URL}/reducirInventario`, {
    solicitud: s,
  })
export const getSalidas = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getTiposSalida = () =>
  axios.get(`${API_URL}/${REQUEST_URL}/tiposSalida`)

export const sentToSII = async dte =>
  axios.post(`${SII_API_URL}/post_dte_borrador`, dte)

export const uploadGuiaS3 = async (name, file, folder) => {
  try {
    if (typeof file === 'string' || file instanceof String) {
      if (file.includes('amazonaws')) {
        return
      }
    }
    const region = 'sa-east-1'
    const client = new S3Client({
      region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region }),
        identityPoolId: 'sa-east-1:cd080295-f30a-4d28-a3bc-2e09b63cf27d',
      }),
    })
    const params = {
      Bucket: 'hezatrade',
      Key: folder + name,
      Body: file,
      ContentEncoding: 'base64',
      ContentType: file.type,
    }
    return await client.send(new PutObjectCommand(params)).catch(err => {
      throw err
    })
  } catch (err) {
    console.log('Error', err)
    throw err
  }
}
