import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  desviaciones: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'deviaciones',
  initialState,
  reducers: {
    setDesviaciones: (state, action) => {
      state.desviaciones = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setDesviaciones, setTotalCount } = Slice.actions
export default Slice.reducer
