import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tomas_inventario: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'tomas_inventario',
  initialState,
  reducers: {
    setTomasInventario: (state, action) => {
      state.tomas_inventario = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setTomasInventario, setTotalCount } = Slice.actions
export default Slice.reducer
