import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tipos_salidas: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'tiposSal',
  initialState,
  reducers: {
    setTiposSalidas: (state, action) => {
      state.tipos_salidas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setTiposSalidas, setTotalCount } = Slice.actions
export default Slice.reducer
