import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './App.scss'
import { ThemeProvider, createTheme } from '@mui/material'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './Redux/store'
import React from 'react'
import LoadingApp from './LoadingApp'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { esES as DateEsEs } from '@mui/x-date-pickers/locales'
import { esES } from '@mui/material/locale'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AppRouter from './Routes/AppRouter'
import SetupAxios from './Axios/SetupAxios'
import axios from 'axios'

SetupAxios(store, axios)


function App() {
  dayjs.extend(utc)
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: '#10069F',
        },
        // secondary: purple,
      },
    },
    esES,
  )

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingApp />} persistor={persistor}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        {/* Localizador globar para los datepicker */}
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="es"
          localeText={
            DateEsEs.components.MuiLocalizationProvider.defaultProps.localeText
          }>
          <React.Suspense fallback={<LoadingApp />}>
            <ThemeProvider theme={theme}>
              <AppRouter />
            </ThemeProvider>
          </React.Suspense>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
