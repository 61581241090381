import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/proveedores' 

export const insertProveedores = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getProveedores = q => axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getProveedor = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateProvedor = (id, c) => axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteProvedor = ids => axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })
