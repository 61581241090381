import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getResumenAction } from '../Redux/dash/actions/getResumenAction'

const useDashResumen = q => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getResumenAction(q))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q])
}

export default useDashResumen
