import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/sub_categorias'

export const insertSubCategoria = c =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getSubCategorias = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getSubCategoria = id =>
  axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateSubCategoria = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deletesubcat = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })
