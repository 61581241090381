import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  centros_costo: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'centros_costo',
  initialState,
  reducers: {
    setCentrosCosto: (state, action) => {
      state.centros_costo = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setCentrosCosto, setTotalCount } = Slice.actions
export default Slice.reducer
