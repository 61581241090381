import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/canales_dist'

export const getCanalesDist = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const insertCanalDist = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getCanalDist = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateCanalDist = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteCanalDist = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })
