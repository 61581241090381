import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/zonas'

export const insertZonas = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getZonas = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getZona = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateZonas = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteZona = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })