import DashboardPage from '../Pages/dashboard/DashboardPage'
import { lazy, useState } from 'react'
import { ProtectedRoute } from '../ProtectedRoute'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'
import Layout from '../Pages/components/Layout'
import AddProdModal from '../Pages/salidas/components/AddProdModal'

const MiPerfilPage = lazy(() => import('../Pages/miperfil/MiPerfilPage'))
const ConfiguracionPage = lazy(
  () => import('../Pages/miperfil/ConfiguracionPage'),
)
const EntradaAddPage = lazy(() => import('../Pages/entradas/EntradaAddPage'))
const EntradaEditPage = lazy(() => import('../Pages/entradas/EntradaEditPage'))
const SalidasAddPage = lazy(() => import('../Pages/salidas/SalidasAddPage'))
const RepSalidasPage = lazy(() => import('../Pages/salidas/RepSalidasPage'))
const RepDesviacionesPage = lazy(
  () => import('../Pages/desviaciones/RepDesviacionesPage'),
)
const RepStockActualPage = lazy(
  () => import('../Pages/stock/RepStockActualPage'),
)
const RepStockBodegasPage = lazy(
  () => import('../Pages/stockBodegas/RepStockBodegasPage'),
)
const RepStockCanalesPage = lazy(
  () => import('../Pages/stockCanales/RepStockCanalesPage'),
)
const RepStockDetallePage = lazy(
  () => import('../Pages/stockDetalle/RepStockDetallePage'),
)
const RepStockCriticoPage = lazy(
  () => import('../Pages/stockCritico/RepStockCriticoPage'),
)
const RepDemandaPage = lazy(() => import('../Pages/demanda/RepDemandaPage'))
const UsersPage = lazy(() => import('../Pages/users/UsersPage'))
const UsersAddPage = lazy(() => import('../Pages/users/UsersAddPage'))
const UsersEditPage = lazy(() => import('../Pages/users/UsersEditPage'))
const UsersViewPage = lazy(() => import('../Pages/users/UsersViewPage'))
const UsersDeletePage = lazy(() => import('../Pages/users/UsersDeletePage'))
const UsersDeletesPage = lazy(() => import('../Pages/users/UsersDeletesPage'))

const BodegasPage = lazy(() => import('../Pages/bodegas/BodegasPage'))
const BodegasAddPage = lazy(() => import('../Pages/bodegas/BodegasAddPage'))
const BodegasViewPage = lazy(() => import('../Pages/bodegas/BodegasViewPage'))
const BodegasDeletePage = lazy(
  () => import('../Pages/bodegas/BodegasDeletePage'),
)
const BodegasDeletesPage = lazy(
  () => import('../Pages/bodegas/BodegasDeletesPage'),
)
const BodegasEditPage = lazy(() => import('../Pages/bodegas/BodegasEditPage'))

const ProductosPage = lazy(() => import('../Pages/productos/ProductosPage'))
const ProductosAddPage = lazy(
  () => import('../Pages/productos/ProductosAddPage'),
)
const ProductosViewPage = lazy(
  () => import('../Pages/productos/ProductosViewPage'),
)
const ProductosDeletePage = lazy(
  () => import('../Pages/productos/ProductosDeletePage'),
)
const ProductosDeletesPage = lazy(
  () => import('../Pages/productos/ProductosDeletesPage'),
)
const ProductosEditPage = lazy(
  () => import('../Pages/productos/ProductosEditPage'),
)

const CategoriasPage = lazy(() => import('../Pages/categorias/CategoriasPage'))
const CategoriasAddPage = lazy(
  () => import('../Pages/categorias/CategoriasAddPage'),
)
const CategoriasViewPage = lazy(
  () => import('../Pages/categorias/CategoriasViewPage'),
)
const CategoriasDeletePage = lazy(
  () => import('../Pages/categorias/CategoriasDeletePage'),
)
const CategoriasDeletesPage = lazy(
  () => import('../Pages/categorias/CategoriasDeletesPage'),
)
const CategoriasEditPage = lazy(
  () => import('../Pages/categorias/CategoriasEditPage'),
)

const CadenasPage = lazy(() => import('../Pages/cadena/CadenasPage'))
const CadenasAddPage = lazy(() => import('../Pages/cadena/CadenasAddPage'))
const CadenasViewPage = lazy(() => import('../Pages/cadena/CadenasViewPage'))
const CadenasDeletePage = lazy(
  () => import('../Pages/cadena/CadenasDeletePage'),
)
const CadenasEditPage = lazy(() => import('../Pages/cadena/CadenasEditPage'))
const CadenasDeletesPage = lazy(
  () => import('../Pages/cadena/CadenasDeletesPage'),
)

const SubCategoriasPage = lazy(
  () => import('../Pages/sub_categorias/SubCategoriasPage'),
)
const SubCategoriasAddPage = lazy(
  () => import('../Pages/sub_categorias/SubCategoriasAddPage'),
)
const SubCategoriasEditPage = lazy(
  () => import('../Pages/sub_categorias/SubCategoriasEditPage'),
)
const SubCategoriasViewPage = lazy(
  () => import('../Pages/sub_categorias/SubCategoriasViewPage'),
)
const SubCategoriasDeletePage = lazy(
  () => import('../Pages/sub_categorias/SubCategoriasDeletePage'),
)
const SubCategoriasDeletesPage = lazy(
  () => import('../Pages/sub_categorias/SubCategoriasDeletesPage'),
)
const TraspasoInventarioPage = lazy(
  () => import('../Pages/traspaso_inventario/TraspasoInventarioPage'),
)

const MarcasPage = lazy(() => import('../Pages/marcas/MarcasPage'))
const MarcasAddPage = lazy(() => import('../Pages/marcas/MarcasAddPage'))
const MarcasEditPage = lazy(() => import('../Pages/marcas/MarcasEditPage'))
const MarcasViewPage = lazy(() => import('../Pages/marcas/MarcasViewPage'))
const MarcasDeletePage = lazy(() => import('../Pages/marcas/MarcasDeletePage'))
const MarcasDeletesPage = lazy(
  () => import('../Pages/marcas/MarcasDeletesPage'),
)

const ZonasPage = lazy(() => import('../Pages/zonas/ZonasPage'))
const ZonasAddPage = lazy(() => import('../Pages/zonas/ZonasAddPage'))
const ZonasEditPage = lazy(() => import('../Pages/zonas/ZonasEditPage'))
const ZonasViewPage = lazy(() => import('../Pages/zonas/ZonasViewPage'))
const ZonasDeletePage = lazy(() => import('../Pages/zonas/ZonasDeletePage'))
const ZonasDeletesPage = lazy(() => import('../Pages/zonas/ZonasDeletesPage'))

const PerfilesPage = lazy(() => import('../Pages/perfiles/PerfilesPage'))
const PerfilesAddPage = lazy(() => import('../Pages/perfiles/PerfilesAddPage'))
const PerfilesEditPage = lazy(
  () => import('../Pages/perfiles/PerfilesEditPage'),
)
const PerfilesViewPage = lazy(
  () => import('../Pages/perfiles/PerfilesViewPage'),
)
const PerfilesDeletePage = lazy(
  () => import('../Pages/perfiles/PerfilesDeletePage'),
)

const CentrosCostoPage = lazy(
  () => import('../Pages/centros_costo/CentrosCostoPage'),
)
const CentrosCostoAddPage = lazy(
  () => import('../Pages/centros_costo/CentrosCostoAddPage'),
)
const CentrosCostoEditPage = lazy(
  () => import('../Pages/centros_costo/CentrosCostoEditPage'),
)
const CentrosCostoViewPage = lazy(
  () => import('../Pages/centros_costo/CentrosCostoViewPage'),
)
const CentrosCostoDeletePage = lazy(
  () => import('../Pages/centros_costo/CentrosCostoDeletePage'),
)
const CentrosCostoDeletesPage = lazy(
  () => import('../Pages/centros_costo/CcostoDeletesPage'),
)

const TiendasPage = lazy(() => import('../Pages/tiendas/TiendasPage'))
const TiendasAddPage = lazy(() => import('../Pages/tiendas/TiendasAddPage'))
const TiendasEditPage = lazy(() => import('../Pages/tiendas/TiendasEditPage'))
const TiendasViewPage = lazy(() => import('../Pages/tiendas/TiendasViewPage'))
const TiendasDeletePage = lazy(
  () => import('../Pages/tiendas/TiendasDeletePage'),
)
const TiendasDeletesPage = lazy(
  () => import('../Pages/tiendas/TiendasDeletesPage'),
)

const SolicitudesPage = lazy(
  () => import('../Pages/solicitudes/SolicitudesPage'),
)
const SolicitudesAddPage = lazy(
  () => import('../Pages/solicitudes/SolicitudesAddPage'),
)
const SolicitudesDeletePage = lazy(
  () => import('../Pages/solicitudes/SolicitudesDeletePage'),
)
const SolicitudesEditPage = lazy(
  () => import('../Pages/solicitudes/SolicitudesEditPage'),
)
const SolicitudesClonePage = lazy(
  () => import('../Pages/solicitudes/SolicitudesClonePage'),
)
const SolicitudesViewPage = lazy(
  () => import('../Pages/solicitudes/SolicitudesViewPage'),
)

const CanalesDistPage = lazy(
  () => import('../Pages/canales_dist/CanalesDistPage'),
)
const CanalesDistAddPage = lazy(
  () => import('../Pages/canales_dist/CanalesDistAddPage'),
)
const CanalesDistDeletePage = lazy(
  () => import('../Pages/canales_dist/CanalesDistDeletePage'),
)
const CanalesDistEditPage = lazy(
  () => import('../Pages/canales_dist/CanalesDistEditPage'),
)
const CanalesDistViewPage = lazy(
  () => import('../Pages/canales_dist/CanalesDistViewPage'),
)
const CanalesDistDeletesPage = lazy(
  () => import('../Pages/canales_dist/CanalesDistDeletesPage'),
)

const RepEntradasPage = lazy(() => import('../Pages/entradas/RepEntradasPage'))
const DespachosPage = lazy(() => import('../Pages/despachos/DespachosPage'))
const DespachosViewPage = lazy(
  () => import('../Pages/despachos/DespachosViewPage'),
)
const DespachosEditPage = lazy(
  () => import('../Pages/despachos/DespachosEditPage'),
)
//Rutas Proveedores
const ProveedoresPage = lazy(
  () => import('../Pages/proveedores/ProveedoresPage'),
)
const ProveedoresAddPage = lazy(
  () => import('../Pages/proveedores/ProveedoresAddPage'),
)
const ProveedoresViewPage = lazy(
  () => import('../Pages/proveedores/ProveedoresViewPage'),
)
const ProveedoresDeletePage = lazy(
  () => import('../Pages/proveedores/ProveedoresDeletePage'),
)
const ProveedoresEditPage = lazy(
  () => import('../Pages/proveedores/ProveedoresEditPage'),
)
const ProveedoresDeletesPage = lazy(
  () => import('../Pages/proveedores/ProveedoresDeletesPage'),
)
const ReducirInventarioPage = lazy(
  () => import('../Pages/reducir_inventario/ReducirInventarioPage'),
)
const TomaInventarioPage = lazy(
  () => import('../Pages/toma_inventario/TomaInventarioPage'),
)
const TomaInventarioAddPage = lazy(
  () => import('../Pages/toma_inventario/TomaInventarioAddPage'),
)
const TomaInventarioEditPage = lazy(
  () => import('../Pages/toma_inventario/TomaInventarioEditPage'),
)
const TomaInventarioProcessPage = lazy(
  () => import('../Pages/toma_inventario/TomaInventarioProcessPage'),
)
const TomaInventarioDeletePage = lazy(
  () => import('../Pages/toma_inventario/TomaInventarioDeletePage'),
)
const TomaInventarioViewPage = lazy(
  () => import('../Pages/toma_inventario/TomaInventarioViewPage'),
)

const LogedRouter = () => {
  const location = useLocation()
  const state = location.state
  const [addModalData, setAddModalData] = useState({})

  return (
    <Layout>
      <Routes location={state?.backgroundLocation || location}>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        {/* ENTRADAS */}
        <Route
          path="/entradas/add"
          element={
            <ProtectedRoute>
              <EntradaAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/entradas/edit/:id"
          element={
            <ProtectedRoute>
              <EntradaEditPage />
            </ProtectedRoute>
          }
        />

        {/* DESPACHOS */}
        <Route
          path="/despachos"
          element={
            <ProtectedRoute>
              <DespachosPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/despachos/view/:id"
          element={
            <ProtectedRoute>
              <DespachosViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/despachos/edit/:id"
          element={
            <ProtectedRoute>
              <DespachosEditPage />
            </ProtectedRoute>
          }
        />
        {/* SOLICITUDES */}
        <Route
          path="/solicitudes"
          element={
            <ProtectedRoute>
              <SolicitudesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solicitudes/add"
          element={
            <ProtectedRoute>
              <SolicitudesAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solicitudes/delete/:id"
          element={
            <ProtectedRoute>
              <SolicitudesDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solicitudes/edit/:id"
          element={
            <ProtectedRoute>
              <SolicitudesEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solicitudes/clone/:id"
          element={
            <ProtectedRoute>
              <SolicitudesClonePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solicitudes/view/:id"
          element={
            <ProtectedRoute>
              <SolicitudesViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salidas/add/:id"
          element={
            <ProtectedRoute>
              <SalidasAddPage addModalData={addModalData} />
            </ProtectedRoute>
          }
        />
        {/* TRASPASO INVENTARIO */}
        <Route
          path="/traspaso_inventario"
          element={
            <ProtectedRoute>
              <TraspasoInventarioPage />
            </ProtectedRoute>
          }
        />
        {/* TOMA INVENTARIO */}
        <Route
          path="/toma_inventario"
          element={
            <ProtectedRoute>
              <TomaInventarioPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/toma_inventario/add"
          element={
            <ProtectedRoute>
              <TomaInventarioAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/toma_inventario/edit/:id"
          element={
            <ProtectedRoute>
              <TomaInventarioEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/toma_inventario/process/:id"
          element={
            <ProtectedRoute>
              <TomaInventarioProcessPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/toma_inventario/delete/:id"
          element={
            <ProtectedRoute>
              <TomaInventarioDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/toma_inventario/view/:id"
          element={
            <ProtectedRoute>
              <TomaInventarioViewPage />
            </ProtectedRoute>
          }
        />
        {/* REDUCIR INVENTARIO */}
        <Route
          path="/reducir_inventario"
          element={
            <ProtectedRoute>
              <ReducirInventarioPage />
            </ProtectedRoute>
          }
        />

        {/* REPORTES */}
        <Route
          path="/reportes/entradas"
          element={
            <ProtectedRoute>
              <RepEntradasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/salidas"
          element={
            <ProtectedRoute>
              <RepSalidasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/desviaciones"
          element={
            <ProtectedRoute>
              <RepDesviacionesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/stock"
          element={
            <ProtectedRoute>
              <RepStockActualPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/stockBodegas"
          element={
            <ProtectedRoute>
              <RepStockBodegasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/stockCanales"
          element={
            <ProtectedRoute>
              <RepStockCanalesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/stockDetalle"
          element={
            <ProtectedRoute>
              <RepStockDetallePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/stockCritico"
          element={
            <ProtectedRoute>
              <RepStockCriticoPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reportes/demanda"
          element={
            <ProtectedRoute>
              <RepDemandaPage />
            </ProtectedRoute>
          }
        />
        {/* PRODUCTOS */}
        <Route
          path="/productos"
          element={
            <ProtectedRoute>
              <ProductosPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productos/add"
          element={
            <ProtectedRoute>
              <ProductosAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productos/edit/:id"
          element={
            <ProtectedRoute>
              <ProductosEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productos/view/:id"
          element={
            <ProtectedRoute>
              <ProductosViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productos/delete/:id"
          element={
            <ProtectedRoute>
              <ProductosDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productos/deletes/:ids"
          element={
            <ProtectedRoute>
              <ProductosDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* BODEGAS */}
        <Route
          path="/bodegas"
          element={
            <ProtectedRoute>
              <BodegasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bodegas/add"
          element={
            <ProtectedRoute>
              <BodegasAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bodegas/edit/:id"
          element={
            <ProtectedRoute>
              <BodegasEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bodegas/view/:id"
          element={
            <ProtectedRoute>
              <BodegasViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bodegas/delete/:id"
          element={
            <ProtectedRoute>
              <BodegasDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bodegas/deletes/:ids"
          element={
            <ProtectedRoute>
              <BodegasDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* CATEGORIAS */}
        <Route
          path="/categorias"
          element={
            <ProtectedRoute>
              <CategoriasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categorias/add"
          element={
            <ProtectedRoute>
              <CategoriasAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categorias/edit/:id"
          element={
            <ProtectedRoute>
              <CategoriasEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categorias/deletes/:ids"
          element={
            <ProtectedRoute>
              <CategoriasDeletesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categorias/view/:id"
          element={
            <ProtectedRoute>
              <CategoriasViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categorias/delete/:id"
          element={
            <ProtectedRoute>
              <CategoriasDeletePage />
            </ProtectedRoute>
          }
        />
        {/* CADENAS */}
        <Route
          path="/cadenas"
          element={
            <ProtectedRoute>
              <CadenasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadenas/add"
          element={
            <ProtectedRoute>
              <CadenasAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadenas/edit/:id"
          element={
            <ProtectedRoute>
              <CadenasEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadenas/view/:id"
          element={
            <ProtectedRoute>
              <CadenasViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadenas/delete/:id"
          element={
            <ProtectedRoute>
              <CadenasDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadenas/deletes/:ids"
          element={
            <ProtectedRoute>
              <CadenasDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* SUB CATEGORIAS */}
        <Route
          path="/sub_categorias"
          element={
            <ProtectedRoute>
              <SubCategoriasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub_categorias/add"
          element={
            <ProtectedRoute>
              <SubCategoriasAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub_categorias/edit/:id"
          element={
            <ProtectedRoute>
              <SubCategoriasEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub_categorias/view/:id"
          element={
            <ProtectedRoute>
              <SubCategoriasViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub_categorias/delete/:id"
          element={
            <ProtectedRoute>
              <SubCategoriasDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub_categorias/deletes/:ids"
          element={
            <ProtectedRoute>
              <SubCategoriasDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* MARCAS */}
        <Route
          path="/marcas"
          element={
            <ProtectedRoute>
              <MarcasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marcas/add"
          element={
            <ProtectedRoute>
              <MarcasAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marcas/edit/:id"
          element={
            <ProtectedRoute>
              <MarcasEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marcas/view/:id"
          element={
            <ProtectedRoute>
              <MarcasViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marcas/delete/:id"
          element={
            <ProtectedRoute>
              <MarcasDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marcas/deletes/:ids"
          element={
            <ProtectedRoute>
              <MarcasDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* PERFILES */}
        <Route
          path="/perfiles"
          element={
            <ProtectedRoute>
              <PerfilesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/perfiles/add"
          element={
            <ProtectedRoute>
              <PerfilesAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/perfiles/edit/:id"
          element={
            <ProtectedRoute>
              <PerfilesEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/perfiles/view/:id"
          element={
            <ProtectedRoute>
              <PerfilesViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/perfiles/delete/:id"
          element={
            <ProtectedRoute>
              <PerfilesDeletePage />
            </ProtectedRoute>
          }
        />
        {/* TIENDAS */}
        <Route
          path="/tiendas"
          element={
            <ProtectedRoute>
              <TiendasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tiendas/add"
          element={
            <ProtectedRoute>
              <TiendasAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tiendas/edit/:id"
          element={
            <ProtectedRoute>
              <TiendasEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tiendas/view/:id"
          element={
            <ProtectedRoute>
              <TiendasViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tiendas/delete/:id"
          element={
            <ProtectedRoute>
              <TiendasDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tiendas/deletes/:ids"
          element={
            <ProtectedRoute>
              <TiendasDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* ZONAS */}
        <Route
          path="/zonas"
          element={
            <ProtectedRoute>
              <ZonasPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/zonas/add"
          element={
            <ProtectedRoute>
              <ZonasAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/zonas/edit/:id"
          element={
            <ProtectedRoute>
              <ZonasEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/zonas/view/:id"
          element={
            <ProtectedRoute>
              <ZonasViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/zonas/delete/:id"
          element={
            <ProtectedRoute>
              <ZonasDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/zonas/deletes/:ids"
          element={
            <ProtectedRoute>
              <ZonasDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* CANALES DIST */}
        <Route
          path="/canales_dist"
          element={
            <ProtectedRoute>
              <CanalesDistPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/canales_dist/add"
          element={
            <ProtectedRoute>
              <CanalesDistAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/canales_dist/edit/:id"
          element={
            <ProtectedRoute>
              <CanalesDistEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/canales_dist/view/:id"
          element={
            <ProtectedRoute>
              <CanalesDistViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/canales_dist/delete/:id"
          element={
            <ProtectedRoute>
              <CanalesDistDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/canales_dist/deletes/:ids"
          element={
            <ProtectedRoute>
              <CanalesDistDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* CENTROS COSTO */}
        <Route
          path="/centros_costo"
          element={
            <ProtectedRoute>
              <CentrosCostoPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/centros_costo/add"
          element={
            <ProtectedRoute>
              <CentrosCostoAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/centros_costo/edit/:id"
          element={
            <ProtectedRoute>
              <CentrosCostoEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/centros_costo/view/:id"
          element={
            <ProtectedRoute>
              <CentrosCostoViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/centros_costo/delete/:id"
          element={
            <ProtectedRoute>
              <CentrosCostoDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ccosto/deletes/:ids"
          element={
            <ProtectedRoute>
              <CentrosCostoDeletesPage />
            </ProtectedRoute>
          }
        />
        {/* USERS */}
        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <UsersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/add"
          element={
            <ProtectedRoute>
              <UsersAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/edit/:id"
          element={
            <ProtectedRoute>
              <UsersEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/view/:id"
          element={
            <ProtectedRoute>
              <UsersViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/delete/:id"
          element={
            <ProtectedRoute>
              <UsersDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/deletes/:ids"
          element={
            <ProtectedRoute>
              <UsersDeletesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/myprofile"
          element={
            <ProtectedRoute>
              <MiPerfilPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/myprofileconfi"
          element={
            <ProtectedRoute>
              <ConfiguracionPage />
            </ProtectedRoute>
          }
        />
        {/*   PROVEEDORES */}
        <Route
          path="/proveedores"
          element={
            <ProtectedRoute>
              <ProveedoresPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/proveedores/add"
          element={
            <ProtectedRoute>
              <ProveedoresAddPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/proveedores/edit/:id"
          element={
            <ProtectedRoute>
              <ProveedoresEditPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/proveedores/view/:id"
          element={
            <ProtectedRoute>
              <ProveedoresViewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/proveedores/delete/:id"
          element={
            <ProtectedRoute>
              <ProveedoresDeletePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/proveedores/deletes/:ids"
          element={
            <ProtectedRoute>
              <ProveedoresDeletesPage />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route
            path="/salidas/add/:id_sol/modal/:id_prod"
            element={<AddProdModal setAddModalData={setAddModalData} />}
          />
        </Routes>
      )}
    </Layout>
  )
}

export default LogedRouter
