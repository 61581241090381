import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  demanda: [],
  demandaTop: [],
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'demanda',
  initialState,
  reducers: {
    setDemanda: (state, action) => {
      state.demanda = action.payload
    },
    setDemandaTop: (state, action) => {
      state.demandaTop = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setDemanda, setDemandaTop, setTotalCount, setLoading } = Slice.actions
export default Slice.reducer
