import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getSolicitudPendAction } from '../Redux/dash/actions/getSolicitudPendAction'

const useDashSolicitudPend = q => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSolicitudPendAction(q))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q])
}

export default useDashSolicitudPend
