import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  zonas: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'zonas',
  initialState,
  reducers: {
    setZonas: (state, action) => {
      state.zonas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setZonas, setTotalCount } = Slice.actions
export default Slice.reducer
