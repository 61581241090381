import { setSalidas } from '../dashRedux'
import { getSalidas } from '../restFunctios/getSalidas'

export const getSalidasAction = q => {
  return async dispatch => {
    const { data } = await getSalidas(q)
    if (data) {
      dispatch(setSalidas(data.entities))
    } else {
      dispatch(setSalidas([]))
    }
  }
}
