import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  canales_dist: [],
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'canalesDist',
  initialState,
  reducers: {
    setCanalesDist: (state, action) => {
      state.canales_dist = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setCanalesDist, setTotalCount, setLoading } = Slice.actions
export default Slice.reducer
