import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cadenas: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'cadenas',
  initialState,
  reducers: {
    setCadenas: (state, action) => {
      state.cadenas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setCadenas, setTotalCount } = Slice.actions
export default Slice.reducer
