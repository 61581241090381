import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/dashboard'

export const getResumen = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/resumen`, { params: q })
export const getPorcentajesSalEnt = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/porcentajesSalEnt`, { params: q })
export const getSalidasDash = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/salidas`, { params: q })
export const getSolicitudPend = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/solicitudpend`, { params: q })
