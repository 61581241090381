import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/cadenas' 

export const insertCadenas = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getCadenas = q => axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getCadena = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateCadena = (id, c) => axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteCadena = ids =>
    axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })