import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'loginusu-inv-hezatrade',
  storage,
}
const initialState = {
  user: {},
  openSidebar: true,
  empresa: {},
}

export const loginusuSlice = createSlice({
  name: 'loginusu',
  initialState,
  reducers: {
    setLoginusu: (state, action) => {
      state.user = action.payload
    },
    setSidebarOpen: (state, action) => {
      state.openSidebar = action.payload
    },
    logout: () => {
      console.log('logout')
      return initialState
    },
    setEmpresa: (state, action) => {
      state.empresa = action.payload
    },
  },
})

export const { setLoginusu, setSidebarOpen, logout, setEmpresa } = loginusuSlice.actions
export default persistReducer(persistConfig, loginusuSlice.reducer)
