import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/despachos'

export const getDespachos = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getDespacho = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const updateDespacho = (id, despacho) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, despacho)
export const putVoucher = (id, url) =>
  axios.put(`${API_URL}/${REQUEST_URL}/voucher/${id}`, { url: url })
export const putCuartaCopia = (id, url) =>
  axios.put(`${API_URL}/${REQUEST_URL}/cuartacopia/${id}`, { url: url })
