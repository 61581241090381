import { setPorcentajeSalEnt } from '../dashRedux'
import { getPorcentajesSalEnt } from '../restFunctios/getPorcentajeSalEnt'

export const getPorcentajeSalEntAction = q => {
  return async dispatch => {
    const { data } = await getPorcentajesSalEnt(q)
    if (data) {
      dispatch(setPorcentajeSalEnt(data))
    } else {
      dispatch(setPorcentajeSalEnt({}))
    }
  }
}
