import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resumen: {},
  porcentajeSalEnt: {},
  salidas: [],
  solicitudesPend: [],
}

export const Slice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    setResumen: (state, action) => {
      state.resumen = action.payload
    },
    setPorcentajeSalEnt: (state, action) => {
      state.porcentajeSalEnt = action.payload
    },
    setSalidas: (state, action) => {
      state.salidas = action.payload
    },
    setSolicitudesPend: (state, action) => {
      state.solicitudesPend = action.payload
    },
  },
})

export const { setResumen, setPorcentajeSalEnt, setSalidas, setSolicitudesPend } = Slice.actions
export default Slice.reducer
