import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bodegas: [],
  totalCount: 0,
  loading: false,
}

export const comSlice = createSlice({
  name: 'bod',
  initialState,
  reducers: {
    setBodegas: (state, action) => {
      state.bodegas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setBodegas, setTotalCount, setLoading } = comSlice.actions
export default comSlice.reducer
