import { setSolPendParCant } from '../solRedux'
import { getSolPendParCant } from '../restFunctios/getSolPendParCant'

export const getSolPendParCantAction = q => {
  return async dispatch => {
    const { data } = await getSolPendParCant(q)
    if (data) {
      dispatch(setSolPendParCant(data.count))
    } else {
      dispatch(setSolPendParCant([]))
    }
  }
}
