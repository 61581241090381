import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/entradas'

export const insertInventMas = dp =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, dp)
export const updateInventario = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const getEntradas = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getEntrada = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const getTiposEntrada = () =>
  axios.get(`${API_URL}/${REQUEST_URL}/tiposEntrada`)
