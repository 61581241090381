import { forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink as NavLinkBase } from 'react-router-dom'
import { setSidebarOpen } from '../../Redux/loginusu/loginusuRedux'

const NavLink = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const closeDrawer = () => {
    dispatch(setSidebarOpen(false))
  }
  return (
    <NavLinkBase
      ref={ref}
      {...props}
      onClick={closeDrawer}
      className={({ isActive }) =>
        isActive ? `${props.className} Mui-selected` : props.className
      }
    />
  )
})

export default NavLink
