import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

const LoadingApp = () => (
  <div>
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  </div>
)

export default LoadingApp
