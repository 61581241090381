import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/bodegas'

export const insertBodega = c => axios.post(`${API_URL}/${REQUEST_URL}/`, c)
export const getBodegas = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getBodega = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
export const getStockBodegaByIdAndProd = (id, productos) =>
  axios.get(`${API_URL}/${REQUEST_URL}/stock/${id}`, { params: { productos } })
export const updateBodega = (id, c) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, c)
export const deleteBodega = ids =>
  axios.delete(`${API_URL}/${REQUEST_URL}/`, { params: { ids } })
