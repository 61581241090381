import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import * as server from '../../Axios'
import { useDispatch } from 'react-redux'
import { setLoginusu } from '../../Redux/loginusu/loginusuRedux'

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({
    loginame: '',
    password: '',
    failedLoginAttempts: 0,
  })
  const enter = () => {
    setLoading(true)
    server
      .login(user)
      .then(r => {
        if (r.status === 200 && r.data) {
          navigate('/dashboard')
          dispatch(setLoginusu(r.data))
        } else {
          setUser({
            ...user,
            failedLoginAttempts: user.failedLoginAttempts + 1,
          })
          alert('usuario no existe')
        }

        //dispatch(addAlert({ msg: 'Cliente ingresado con éxito' }))
      })
      .finally(() => setLoading(false))
  }

  const validateSubmit = e => {
    const err = {}
    for (const el of e.target) {
      if (el.name) {
        const v = el.checkValidity()

        if (!v) err[el.name] = true
      }
    }
    setErrors({ ...err })
    e.preventDefault()
    const valid = e.target.checkValidity()
    if (!valid) {
      setShowErrors(true)
      alert('faltan campos')
    } else enter()

    return false
  }

  const onChangeDat = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src="/images/logo.png" alt="logo" width={300} />
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', mt: 5 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Ingreso a sistema
        </Typography>
        <Box
          component="form"
          onSubmit={validateSubmit}
          noValidate
          sx={{ mt: 1 }}>
          <TextField
            error={showErrors && errors.loginame}
            margin="normal"
            required
            fullWidth
            id="loginame"
            label="Correo"
            name="loginame"
            autoComplete="loginame"
            autoFocus
            onChange={onChangeDat}
            value={user.loginame}
          />
          <TextField
            error={showErrors && errors.password}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChangeDat}
            value={user.password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={user.failedLoginAttempts > 9 || loading}>
            Entrar
          </Button>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="primary" />
            </Box>
          )}
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Olvidó su contraseña?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
export default LoginPage
