import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users: [],
  totalCount: 0,
  loading: false,
}

export const ususSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setUsers, setTotalCount, setLoading } = ususSlice.actions
export default ususSlice.reducer
