import { setResumen } from '../dashRedux'
import { getResumen } from '../restFunctios/getResumen'

export const getResumenAction = q => {
  return async dispatch => {
    const { data } = await getResumen(q)
    if (data) {
      dispatch(setResumen(data))
    } else {
      dispatch(setResumen({}))
    }
  }
}
