import * as React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from 'react-router-dom'
import { Chip, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const SolicitudPend = ({ row, idx }) => {
  const nav = useNavigate()
  const labelId = `enhanced-table-checkbox-${idx}`

  const selectChipEstado = (id, name) => {
    switch (id) {
      case 1:
        return <Chip label={name} color="error" size="small" /> //Pendiente 1 rojo
      case 2:
        return <Chip label={name} color="warning" size="small" /> //Parcial 2 naranjo
      default:
        return <></>
    }
  }
  
  const handleView = () => nav(`/solicitudes/view/${row.id}`)

  return (
    <TableRow key={row.id}>
      <TableCell component="th" id={labelId} scope="row">
        {row.sol_fecha_entrega}
      </TableCell>
      <TableCell>{row.sol_nombre}</TableCell>
      <TableCell component="th" id={labelId} scope="row">
        {row.usu_nombre} {row.usu_pat} {row.usu_mat}
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        {row.tienda_nombre}
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        {row.tienda_direccion}
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        {row.sol_recibe}
      </TableCell>
      <TableCell component="th" id={labelId} scope="row">
        {selectChipEstado(row.id_estado_sol, row.estado_sol_nombre)}
      </TableCell>
      <IconButton aria-label="view" color="primary" onClick={handleView}>
        <SearchIcon />
      </IconButton>
    </TableRow>
  )
}

export default SolicitudPend
