import { logout } from '../Redux/loginusu/loginusuRedux'

const SetupAxios = (store, axios) => {
  axios.defaults.timeout = 120 * 1000 // 2 minutes

  axios.interceptors.request.use(
    config => {
      const {
        loginusu: { user },
      } = store.getState()

      if (user.accessToken)
        config.headers.Authorization = `Bearer ${user.accessToken}`

      return config
    },
    err => Promise.reject(err),
  )

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response)
        if (error.response.status === 401) store.dispatch(logout())

      return Promise.reject(error)
    },
  )
}

export default SetupAxios
